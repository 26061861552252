import { stringToHex } from "viem";
import { resourceToHex } from "@latticexyz/common";
export const SPAWN_SETTLEMENT = stringToHex("SpawnSettlement", { size: 32 });
export const BYTES32_ZERO = "0x0000000000000000000000000000000000000000000000000000000000000000";
export const MAP_SYSTEM_ID = resourceToHex({ type: "system", namespace: "", name: "MapSystem" });
export const MATCH_SYSTEM_ID = resourceToHex({ type: "system", namespace: "", name: "MatchSystem" });
export const NFT_SYSTEM_ID = resourceToHex({ type: "system", namespace: "", name: "NFTSystem" });
export const TOKEN_SYSTEM_ID = resourceToHex({ type: "system", namespace: "", name: "TokenSystem" });
export const PLAYER_SYSTEM_ID = resourceToHex({ type: "system", namespace: "", name: "PlayerSystem" });
export const ACTION_SYSTEM_ID = resourceToHex({ type: "system", namespace: "", name: "ActionSystem" });
