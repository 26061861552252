export default {
  components: {
    TokenContract: {
      key: [],
      schema: {
        value: "address",
      },
    },
  }
}
