import { defineWorld } from "@latticexyz/world";
import itemConfig from "./mud.item.config";
import nftConfig from "./mud.nft.config";
import tokenConfig from "./mud.token.config";
import moduleConfig from "./mud.module.config";

import { defineERC20Module } from "@latticexyz/world-module-erc20/internal"; // only work with version 2.2.13

export default defineWorld({
  deploy: {
    upgradeableWorldImplementation: true,
  },
  codegen: {
    outputDirectory: "codegen",
  },
  userTypes: {
    EncodedLengths: { filePath: "@latticexyz/store/src/EncodedLengths.sol", type: "bytes32" },
    ResourceId: { filePath: "@latticexyz/store/src/ResourceId.sol", type: "bytes32" },
  },
  enums: {
    UnitTypes: [
      "Unknown", // 0
      "Hero", // 1
      "Druid", // 2
      "General", // 3
      "Paladin", // 4
    ],
    FightTypes: [
      "Unknown", // 0
      "Hero", // 1
      "Druid", // 2
      "General", // 3
      "Paladin", // 4
    ],
    TerrainTypes: ["Unknown", "Grass", "Mountain", "Forest"],
    StructureTypes: ["Unknown", "Settlement", "SpawnSettlement", "WoodenWall"],
    ...itemConfig.enums,
  },
  modules: [
    ...moduleConfig.modules
  ],
  // excludeSystems: ["SeasonPassOnlySystem"],
  tables: {
    // temporarily used in the client for backwards compat while we update frontend
    // this marks entities (eg. units, structures) as being part of the `matchEntity` match
    // TODO: remove
    Match: {
      type: "offchainTable",
      key: ["matchEntityKey", "entity"],
      schema: {
        matchEntityKey: "bytes32", // same as matchEntity below, but renamed to avoid arg name conflicts in tablegen
        entity: "bytes32",
        matchEntity: "bytes32", // leave this as matchEntity because frontend queries based on this value
      },
    },
    /**
     * If an entity has this it is able to engage in fightState.
     * All values represented in thousands.
     * i.e. 100_000 HP = 100 HP
     */
    FightState: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        health: "int32",
        maxHealth: "int32",
        strength: "int32",
        maxStrength: "int32",
        luck: "int32",
        maxLuck: "int32",
        skill: "int32",
        maxSkill: "int32",
        minRange: "int32",
        maxRange: "int32",
        fightType: "FightTypes",
      },
    },
    /**
     * Set a value for a specific FightType fightState matchup.
     * The value is a percentage bonus or penalty.
     * i,e. 30 = 30% bonus, -30 = 30% penalty
     */
    FightTypeModifier: {
      key: ["attacker", "defender"],
      schema: {
        attacker: "FightTypes",
        defender: "FightTypes",
        // expressed as a percentage
        mod: "int32",
        // We store the keys here to aid in offchain lookups
        // this all fits in one storage slot so it's not a big deal
        attackerFightType: "FightTypes",
        defenderFightType: "FightTypes",
      },
    },
    /**
     * Emitted during fightState to inform client animations.
     */
    FightOutcome: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        attacker: "bytes32",
        defender: "bytes32",
        attackerDamageReceived: "int32",
        defenderDamageReceived: "int32",
        attackerDamage: "int32",
        defenderDamage: "int32",
        ranged: "bool",
        attackerDied: "bool",
        defenderDied: "bool",
        defenderCaptured: "bool",
        blockNumber: "uint256",
        timestamp: "uint256",
      },
    },
    /**
     * Marks an entity as able to move.
     * The value is how many units there are able to move.
     * Represented in thousands.
     * i.e. 1000 = 1 unit.
     */
    Movable: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "int32",
      },
    },
    /**
     * Given to terrain to determine how much it costs to move onto it.
     * Used in conjunction with Movable during path calculation.
     */
    MoveDifficulty: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "int32",
      },
    },
    /**
     * Stores a reference to the address that created a Player entity.
     */
    CreatedByAddress: {
      key: ["matchEntity", "playerEntity"],
      schema: {
        matchEntity: "bytes32",
        playerEntity: "bytes32",
        value: "bytes32",
      },
    },
    /**
     * Used to determine the owner of an entity created in a match.
     * i.e. Player -> Unit
     */
    OwnedBy: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "bytes32",
      },
    },
    /**
     * Marks a player address as a player.
     * Value is an incrementing integer.
     */
    Player: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "uint32",
      },
    },
    /**
     * The position of an entity.
     */
    Position: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        x: "int32",
        y: "int32",
      },
    },
    EntitiesAtPosition: {
      key: ["matchEntity", "x", "y"],
      schema: {
        matchEntity: "bytes32",
        x: "int32",
        y: "int32",
        entities: "bytes32[]",
      },
    },
    /**
     * Set during Player registration to reserve a specific SpawnPoint in a level for a player entity.
     */
    PlayerAtIndex: {
      key: ["matchEntity", "index"],
      schema: {
        matchEntity: "bytes32",
        index: "uint256",
        value: "bytes32",
      },
    },
    /**
     * Used to mark something as an Structure.
     * NOTE: Only use this to determine if something is an Structure contract-side.
     * Specific Structure Types are only used client-side to deteremine rendering.
     */
    StructureType: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "StructureTypes", 
      },
    },
    /**
     * Used to mark something as Terrain.
     * NOTE: Only use this to determine if something is Terrain contract-side.
     * Specific Terrain Types are only used client-side to deteremine rendering.
     */
    TerrainType: {
      type: "offchainTable",
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "TerrainTypes",
      },
    },
    /**
     * Used to mark something as a Unit.
     * NOTE: Only use this to determine if something is a Unit contract-side.
     * Specific Unit Types are only used client-side to deteremine rendering.
     */
    UnitType: {
      type: "offchainTable",
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "UnitTypes",
      },
    },
    /**
     * Whethere this entity blocks the movement of other entities.
     */
    Untraversable: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "bool",
      },
    },
    /**
     * Index for finding a player in a given Match.
     */
    MatchPlayer: {
      key: ["matchEntity", "playerAddress"],
      schema: {
        matchEntity: "bytes32",
        playerAddress: "address",
        playerEntity: "bytes32",
      },
    },

    /**
     * Marks an entity as an admin. Used on address entities.
     */
    Admin: "bool",

    LastMatchIndex: {
      key: [],
      schema: {
        matchIndex: "uint32",
      },
    },
    /**
     * Map match entities to their match index (derived from auto-incrementing LastMatchIndex), used in pool rewards
     */
    MatchIndex: {
      key: ["matchEntity"],
      schema: {
        matchEntity: "bytes32",
        matchIndex: "uint32",
      },
    },
    /**
     * Map match indices to their match entity.
     * Used when calculating rewards for a match to look up matches
     * by their index only.
     */
    MatchIndexToEntity: {
      key: ["matchIndex"],
      schema: {
        matchIndex: "uint32",
        matchEntity: "bytes32",
      },
    },

    // ______________________ MATCH ____________________________

    /**
     * Counter for match-specific entities, used to derive a bytes32 entity via `encodeMatchEntity`.
     * We'll use this as a fallback in case we don't ship match composite keys in time.
     */
    MatchEntityCounter: {
      key: ["matchEntity"],
      schema: {
        matchEntity: "bytes32",
        // ~4 billion entities per match seems like plenty for now
        entityCounter: "uint32",
      },
    },
    /**
     * Kept up to date with all of the player entities present in a Match.
     */
    MatchPlayers: "bytes32[]",
    /**
     * Match gameplay settings.
     */
    MatchConfig: {
      key: ["matchEntity"],
      schema: {
        matchEntity: "bytes32",
        initialized: "bool",
        mapId: "bytes32",
        createdBy: "bytes32",
      },
    },
    /**
     * Whether a match has finished.
     */
    MatchFinished: "bool",
    MatchWon: "bool",
    MatchMapCloneProgress: "uint256",
    /**
     * Time when match Level copying is completed.
     */
    MatchReady: "uint256",

    // ______________________ TEMPLATES + LEVELS ____________________________

    /**
     * Stores the table IDs a template is composed of.
     */
    BPrintComps: "bytes32[]",
    /**
     * Stores the content of each record in a template.
     */
    BPrintCompInits: {
      key: ["bPrintId", "tableId"],
      schema: {
        bPrintId: "bytes32",
        tableId: "ResourceId",

        encodedLengths: "EncodedLengths",
        staticData: "bytes",
        dynamicData: "bytes",
      },
    },
    /**
     * Stores the template for each index in a level.
     */
    MapBPrints: "bytes32[]",
    /**
     * Stores the indices of Level entities with a given `bPrintId`.
     */
    BPrintInMapIndexes: {
      key: ["mapId", "bPrintId"],
      schema: {
        mapId: "bytes32",
        bPrintId: "bytes32",
        value: "uint256[]",
      },
    },
    /**
     * Stores the position of each level index.
     */
    MapPosition: {
      key: ["mapId", "index"],
      schema: {
        mapId: "bytes32",
        index: "uint256",

        x: "int32",
        y: "int32",
      },
    },
    /**
     * Stores the indices of Level entities with the given position.
     */
    MapPositionIndex: {
      key: ["mapId", "x", "y"],
      schema: {
        mapId: "bytes32",
        x: "int32",
        y: "int32",
        value: "uint256[]",
      },
    },
    /**
     * Whether a template is "virtual", meaning it is not instantiated during Level copying.
     */
    StaticMapBPrints: {
      key: ["bPrintId"],
      schema: {
        bPrintId: "bytes32",
        value: "bool",
      },
    },

    MatchTurn: "uint32",
    UnitLastTurn: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "uint32",
      },
    },
    ...nftConfig.components,
    ...tokenConfig.components,
    ...itemConfig.components,

    BelongsToNFT: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "uint32",
      },
    },
    Healing: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "uint32",
      },
    },
  },
});
