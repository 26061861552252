import {
	defineSystem,
	Entity,
	getComponentValue,
	getComponentValueStrict,
	Has,
  HasValue,
	hasComponent,
	Not,
	setComponent,
  removeComponent,
	UpdateType,
} from "@latticexyz/recs";
import { addressToEntityID } from "../../../../../../src/mud/setupNetwork";

import { singletonEntity, decodeEntity } from "@latticexyz/store-sync/recs";
import { PhaserLayer,RenderDepth } from "../../types";
import { StructureTypes } from "../../../../Network";
import { Sprites, Animations } from "../../phaserConstants";
import { runQuery } from "@latticexyz/recs";


export function createUISystem(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: {
        config: { sprites },
        maps: {
          Main,
        },
      },
    },
    parentLayers: {
      network: {
        components: { LK_Balances, MatchFinished, UnitType, MatchTurn } ,
        network: { matchEntity },
        utils: { isOwnedByCurrentPlayer, hasPendingAction: _hasAction},
      },
      headless: {
        components: { NextPosition, InCurrentMatch, OnCooldown },
        api: { canAttack, attack, calculateMovementPath, getAttackableEntities },
      },
      local: {
        api: { selectArea, resetSelection, move, getPotentialPaths, botReact },
        components: { PotentialPath, LocalPosition, AttackableEntities },
      },
    },
    api: {
      playTintedAnimation
    },
    components: { Appearance, SpriteAnimation, HueTint, HoverHighlight },
    globalObjectPoolAbsolute,
  } = layer;
  const units = [...runQuery([Has(SpriteAnimation), HasValue(HueTint, { value: "green" })])];
  function setUp(){
    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-bg`, "Sprite")
    .setTexture(sprites[Sprites.TileInfoBg1].assetKey, sprites[Sprites.TileInfoBg1].frame)
    .setAlpha(1)
    .setDepth(RenderDepth.UI3)
    .setScale(1,1)
    .setPosition(0,50)
    .setDisplaySize(200, 200)

    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-gold-1`, "Sprite")
    .setTexture(sprites[Sprites.TileInfoGold].assetKey, sprites[Sprites.TileInfoGold].frame)
    .setAlpha(1)
    .setDepth(RenderDepth.UI3)
    .setPosition(50, 70)


    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-lk-balance-1`, "Text")
    .setAlign("left")
    .setPosition(110, 90)
    .setFontFamily('"VT323", monospace')
    .setFontSize(24)
    .setStyle({ fontWeight: 'bold' })
    .setDepth(RenderDepth.AlwaysOnTop)
    .setText(`--`);


    // Create box background
    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-end-turn-box`, "Graphics")
      .clear()
      .lineStyle(2, 0x000000)
      .fillStyle(0x666666, 0.5)
      .fillRoundedRect(40, 160, 137, 40, 5)
      .strokeRoundedRect(40, 160, 137, 40, 5)
      .setDepth(RenderDepth.UI3);

    // Add text on top of box
    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-end-turn`, "Text")
      .setAlign("center")
      .setPosition(109, 180)
      .setFontFamily('"VT323", monospace')
      .setFontSize(24)
      .setStyle({ fontWeight: 'bold', stroke: '#000000', strokeThickness: 4 })
      .setDepth(RenderDepth.AlwaysOnTop)
      .setText(`End Turn`)
      .setShadow(2, 2, '#000000', 2, true, true)
      .setOrigin(0.5)
      .setInteractive()
      .on("pointerdown", () => {
        EndTurn()
      });
  }


  function EndTurn(){
    if (!matchEntity) {return }
    const hasPendingAction = _hasAction(singletonEntity);
    if (hasPendingAction) { return; }
    const finished = getComponentValue(MatchFinished, matchEntity);
    if (finished) { return }
    const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
    if (!hoverHighlight) {
      return
    }
    resetSelection();
    
    const entities = [
      ...runQuery([
        Has(UnitType), Has(LocalPosition), Has(InCurrentMatch)
      ]),
    ]

    const playerUnitEntities = [];
    const playerUnitPositions = [];
    const playerCooldowns = [];
    const botUnitEntites = [];
    const botUnitPositions = [];
    
    for (let i=0; i< entities.length; i++) {
      if (isOwnedByCurrentPlayer(entities[i])) {
        playerUnitEntities.push(entities[i])
        playerUnitPositions.push(getComponentValue(LocalPosition, entities[i]))
        playerCooldowns.push(getComponentValue(OnCooldown, entities[i]))
      }else {
        botUnitEntites.push(entities[i])
        botUnitPositions.push(getComponentValue(LocalPosition, entities[i]))
      }
    }
    
    if (playerUnitPositions.length <= 0) return;
    // const playerPosition = playerUnitPositions[playerUnitPositions.length - 1]
    // const playerEntity = playerUnitEntities[playerUnitEntities.length - 1]
    const botReactPlan = {
      attackers: [] as Entity[],
      targetPositions: [] as {x: number, y: number}[],
      defenders: [] as Entity[],
    }
    const allocatedCoords: { x: number, y: number }[] = [];
    loop: for (let i=0; i< botUnitEntites.length; i++) {
        const position = botUnitPositions[i];
        if (!position) continue
        for (let o=0; o< playerUnitEntities.length; o++) {
          if (canAttack(botUnitEntites[i], playerUnitEntities[o]) && position.y == playerUnitPositions[o].y) {
            botReactPlan.attackers.push(botUnitEntites[i])
            botReactPlan.defenders.push(playerUnitEntities[o])
            botReactPlan.targetPositions.push({x:123456, y:123456})
            resetSelection(false)
            continue loop
          }
        }

        selectArea({ ...position, width: 1, height: 1 });
        const potentialPath = getPotentialPaths(botUnitEntites[i]);
        
        if (!potentialPath || potentialPath.x.length == 0) continue

        let min = 100000000; 
        let index = 0; let indexPlayerUnit = 0;
        for (let o=0; o< playerUnitEntities.length; o++) {
          const playerPosition = playerUnitPositions[o]
          for (let j=0; j< potentialPath?.x.length; j++) {
            // Check if the potential path position is already occupied by another bot unit
            if (botUnitPositions.some(pos => pos.x === potentialPath.x[j] && pos.y === potentialPath.y[j])) {
              continue;
            }
            const howClose = Math.abs(potentialPath.x[j] - playerPosition.x) + Math.abs(potentialPath.y[j] - playerPosition.y)
            if (
              howClose<= min &&
              !allocatedCoords.some(coord => coord.x === potentialPath.x[j] && coord.y === potentialPath.y[j])
            ) {
              if (howClose < min){
                min = howClose
                index = j 
                indexPlayerUnit = o
              }else if (potentialPath.y[j] == playerPosition.y && potentialPath.x[j] > playerPosition.x) {
                index = j 
                indexPlayerUnit = o
              }
            }
          }              
        }
        const clickedPosition = {x: potentialPath.x[index], y: potentialPath.y[index]}
        setComponent(NextPosition, botUnitEntites[i], {
          ...clickedPosition,
          userCommittedToPosition: true,
          intendedTarget: undefined,
        });

        allocatedCoords.push(clickedPosition)
        if (min > 1) {
          botReactPlan.attackers.push(botUnitEntites[i])
          botReactPlan.defenders.push("" as Entity)
          botReactPlan.targetPositions.push(clickedPosition)
        }else {
          botReactPlan.attackers.push(botUnitEntites[i])
          botReactPlan.defenders.push(playerUnitEntities[indexPlayerUnit])
          botReactPlan.targetPositions.push(clickedPosition)
        }
        for(let i =0; i< playerUnitEntities.length; i++) {
          setComponent(OnCooldown, playerUnitEntities[i], {value: true})
        }
        const currentMatch = getComponentValue(MatchTurn, matchEntity);
        setTimeout(() => {
          const latestMatch = getComponentValue(MatchTurn, matchEntity);
          if (currentMatch?.value != latestMatch?.value) {
            return
          }
          for(let i =0; i< playerUnitEntities.length; i++) {
            if (playerCooldowns[i]?.value) {
              setComponent(OnCooldown, playerUnitEntities[i], {value: true})
            } else {
              removeComponent(OnCooldown, playerUnitEntities[i])
            }
          }
        }, 2000)
        resetSelection(false);
    }
    botReact(botReactPlan.attackers, botReactPlan.targetPositions, botReactPlan.defenders);
  }
  setUp()
}
