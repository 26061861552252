
import { encodeAbiParameters, stringToHex } from "viem";
import { defineERC20Module } from "@latticexyz/world-module-erc20/internal";

const erc721ModuleArgs = encodeAbiParameters(
  [
    { type: "bytes14" },
    {
      type: "tuple",
      components: [{ type: "string" }, { type: "string" }, { type: "string" }],
    },
  ],
  [stringToHex("LKNFT", { size: 14 }), ["No Valuable Token", "NVT", "http://www.example.com/base/uri/goes/here"]],
);


export default {
  modules: [
    // defineERC20Module({
    //   // The new namespace the module will register
    //   namespace: "LK",
    //   // The metadata of the ERC20 token that will be deployed by the module
    //   name: "LightKeeper",
    //   symbol: "LK",
    // }),
    // {
    //   artifactPath: "@latticexyz/world-modules/out/PuppetModule.sol/PuppetModule.json",
    //   root: false,
    //   args: [],
    // },
    // {
    //   artifactPath: "@latticexyz/world-modules/out/ERC721Module.sol/ERC721Module.json", 
    //   root: false,
    //   args: [
    //     {
    //       type: "bytes",
    //       value: erc721ModuleArgs,
    //     },
    //   ],
    // },
  ]
};
