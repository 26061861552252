export default function getEnv() {
    return {
        BASE_URL: "/",
        MODE: "production",
        DEV: false,
        PROD: true,
        SSR: false,
        VITE_CHAIN_ID: 17069
    }
    return import.meta.env
}
