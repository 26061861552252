import { tileCoordToPixelCoord } from "phaserx";
import { Has, getComponentValueStrict, getComponentValue,UpdateType, defineSystem, Entity, HasValue, runQuery, Not } from "@latticexyz/recs";
import { Animations, adjust } from "../../phaserConstants";
import { PhaserLayer,RenderDepth } from "../../types";
import { singletonEntity, decodeEntity } from "@latticexyz/store-sync/recs";
import { TILE_WIDTH, TILE_HEIGHT } from "../../phaserConstants";
import { Sprites } from "../../phaserConstants";
import { fromHex } from "viem";
import { Tileset } from "../../tilesets/overworld";
import { TerrainTypes } from "../../../../Network";
import { addressToEntityID } from "../../../../../../src/mud/setupNetwork";

export function createDrawHighlightCoordSystem(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: {
        config: { sprites },
        maps: {
          Main,
        },
      },
    },
    components: { HoverHighlight, SpriteAnimation, HueTint, Appearance },
    parentLayers: {
      local: {
        components: { Selected, LocalPosition, PotentialPath },
      },
      network: {
        components: { TerrainType, UnitItems, FightState, NFTState, MoveDifficulty, Untraversable, LK_Balances, TokenContract },
        api: { weaponTopup, getLKBalance },
      }
    },
    api: { 
      playTintedAnimation 
    },
    globalObjectPool,
    globalObjectPoolAbsolute,
  } = layer;
  
  const tileInfo = {
    unitBg: `${singletonEntity}-unitBg`,
    unit: `${singletonEntity}-unit`,
    unitText: `${singletonEntity}-unit-text`,
    unitBar: `${singletonEntity}-unit-health`,
    unitItem: `${singletonEntity}-unit-item`,
    terrainBg: `${singletonEntity}-terrainBg`,
    terrain: `${singletonEntity}-terrain`,
    terrainText: `${singletonEntity}-terrain-text`
  };
  let { tileWidth, tileHeight } = Main
  
  function removeUnitInfo(){
    globalObjectPoolAbsolute.get(tileInfo.unitBg, "Sprite").setAlpha(0)
    globalObjectPoolAbsolute.get(tileInfo.unit, "Sprite").setAlpha(0)
    globalObjectPoolAbsolute.get(tileInfo.unitBar, "Graphics").setAlpha(0)
    globalObjectPoolAbsolute.get(tileInfo.unitText, "Text").setAlpha(0)
    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-lk-balance`, "Text").setAlpha(0)
    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-gold`, "Sprite").setAlpha(0)
    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-plus`, "Sprite").setAlpha(0)
    for (let i = 0; i < 10; i++) {
      const sprite = globalObjectPoolAbsolute.get(`${tileInfo.unitItem}-${i}`, "Sprite");
      sprite.setAlpha(0);
      // globalObjectPoolAbsolute.remove(`${tileInfo.unitItem}-${i}`);
    }
  }
  
  defineSystem(world, [Has(HoverHighlight)], ({ entity, type }) => {
    if (type === UpdateType.Enter) {
      const spriteBGConfig = sprites[Sprites.TileInfoBg];
      globalObjectPoolAbsolute.get(tileInfo.terrainBg, "Sprite")
      .setTexture(spriteBGConfig.assetKey, spriteBGConfig.frame)
      .setAlpha(1)
      .setDepth(RenderDepth.UI3)
      .setScale(2,1)
      .setPosition(window.innerWidth - 200, window.innerHeight - 100)

      globalObjectPoolAbsolute.get(tileInfo.terrain, "Sprite")
        .setScale(1,1)
        .setAlpha(1)
        .setDepth(RenderDepth.AlwaysOnTop)
        .setPosition(window.innerWidth - 180, window.innerHeight - 92)

      globalObjectPoolAbsolute.get(`${tileInfo.terrainText}`, "Text")
      .setAlign("left")
      .setPosition(window.innerWidth - 70, window.innerHeight - 60)
      .setFontFamily('"VT323", monospace')
      .setFontSize(18)
      .setStyle({ fontWeight: 'bold' })
      .setDepth(RenderDepth.AlwaysOnTop)
      .setOrigin(0.5)
    }

    const hoverHighlght = getComponentValueStrict(HoverHighlight, singletonEntity);
    const highlightedEntity =
    hoverHighlght &&
      [...runQuery([HasValue(LocalPosition, { x: hoverHighlght.x, y: hoverHighlght.y }), Has(TerrainType)])][0];
    if (!highlightedEntity) {
      return;
    }

    /**
     * Terrain texture
     */
    const sprite = globalObjectPoolAbsolute.get(tileInfo.terrain, "Sprite");
    const text = globalObjectPoolAbsolute.get(`${tileInfo.terrainText}`, "Text")
    const terrainType = getComponentValue(TerrainType, highlightedEntity);
    const mov = getComponentValue(MoveDifficulty, highlightedEntity);
    const untraversable = getComponentValue(Untraversable, highlightedEntity);

    globalObjectPoolAbsolute.get(tileInfo.terrainBg, "Sprite").setAlpha(1);
    if (untraversable && untraversable.value) {
      sprite.setTexture(sprites[Sprites.TerrainUntraversable].assetKey, sprites[Sprites.TerrainUntraversable].frame);
      text.setText(`Mov:--`)
    }else {
      sprite.setTexture(sprites[Sprites.TerrainGrass].assetKey, sprites[Sprites.TerrainGrass].frame)
      text.setText(`Mov:-${mov.value/1000}`)
    }
    
    // const spriteConfig = sprites[appearance.value as 0];
    // sprite.setTexture(spriteConfig.assetKey, spriteConfig.frame);
    // sprite.setDepth(RenderDepth.AlwaysOnTop);
  })

  let highlightedOne: Entity | null = null

  defineSystem(world, [Has(HoverHighlight)], ({ entity, type }) => {
    const healthBarWidth = 150;
    const healthBarHeight = 10;
    const healthBarX = window.innerWidth - 260;
    const healthBarY = 50;
    if (type === UpdateType.Enter) {
      const spriteBGConfig = sprites[Sprites.TileInfoBg]
      globalObjectPoolAbsolute.get(tileInfo.unitBg, "Sprite")
        .setTexture(spriteBGConfig.assetKey, spriteBGConfig.frame)
        .setDepth(RenderDepth.UI3)
        .setScale(5,3)
        .setPosition(window.innerWidth - 500, 0)

      globalObjectPoolAbsolute.get(tileInfo.unit, "Sprite")
        .setDepth(RenderDepth.AlwaysOnTop)
        .setPosition(window.innerWidth - 500, -30)

      globalObjectPoolAbsolute.get(`${tileInfo.unitText}`, "Text")
        .setAlign("left")
        .setPosition(healthBarX + 20, healthBarY+40)
        .setFontFamily('"VT323", monospace')
        .setFontSize(24)
        .setStyle({ fontWeight: 'bold' })
        .setDepth(RenderDepth.AlwaysOnTop)
        .setOrigin(0.5)
      globalObjectPoolAbsolute.get(`${singletonEntity}-ui-lk-balance`, "Text")
        .setAlign("left")
        .setPosition(healthBarX - 120, healthBarY+205)
        .setFontFamily('"VT323", monospace')
        .setFontSize(24)
        .setStyle({ fontWeight: 'bold' })
        .setDepth(RenderDepth.AlwaysOnTop)
        .setText("--")

      
        globalObjectPoolAbsolute.get(`${singletonEntity}-ui-gold`, "Sprite")
        .setTexture(sprites[Sprites.TileInfoGold].assetKey, sprites[Sprites.TileInfoGold].frame)
        .setDepth(RenderDepth.AlwaysOnTop)
        .setPosition(healthBarX - 180, healthBarY+185)

        globalObjectPoolAbsolute.get(`${singletonEntity}-ui-plus`, "Sprite")
        .setTexture(sprites[Sprites.TileInfoPlus].assetKey, sprites[Sprites.TileInfoPlus].frame)
        .setAlpha(1)
        .setScale(1, 1)
        .setDepth(RenderDepth.AlwaysOnTop)
        .setPosition(healthBarX - 150, healthBarY+70)
        .setInteractive()
        .on("pointerdown", () => {
          weaponTopup(highlightedOne)
        })
    }

    const potentialPath = runQuery([Has(PotentialPath)]);
    if (potentialPath && potentialPath.size > 0) {
      return;
    }
    removeUnitInfo()
    const hoverHighlght = getComponentValueStrict(HoverHighlight, singletonEntity);
    const highlightedEntity =
    hoverHighlght &&
      [...runQuery([HasValue(LocalPosition, { x: hoverHighlght.x, y: hoverHighlght.y }), Not(TerrainType)])][0];
    if (!highlightedEntity) {
      return;
    }
    highlightedOne = highlightedEntity

    /**
     * Background if unit found
     */

    globalObjectPoolAbsolute.get(tileInfo.unitBg, "Sprite").setAlpha(1);


    /**
     * Unit Anim at current tile
     */
    const animation = getComponentValueStrict(SpriteAnimation, highlightedEntity);
    const hueTint = getComponentValueStrict(HueTint, highlightedEntity).value;
    globalObjectPoolAbsolute.get(tileInfo.unit, "Sprite").setAlpha(1);
    playTintedAnimation(tileInfo.unit, animation.value as Animations, hueTint,() => {}, true);

    if (hueTint === "green") {
      globalObjectPoolAbsolute.get(`${singletonEntity}-ui-lk-balance`, "Text").setAlpha(1);
      globalObjectPoolAbsolute.get(`${singletonEntity}-ui-gold`, "Sprite").setAlpha(1);
    }
    /**
     * Unit stats
     */
    const fightState = getComponentValue(FightState, highlightedEntity);
    const nftState = getComponentValue(NFTState, highlightedEntity);
    if (fightState) {
      if (hueTint === "green" && nftState.weaponLeft < nftState.weaponCap) {
        globalObjectPoolAbsolute.get(`${singletonEntity}-ui-plus`, "Sprite").setAlpha(1)
      }
      // healthBar bar
      const bar = globalObjectPoolAbsolute.get(tileInfo.unitBar, "Graphics") as Phaser.GameObjects.Rectangle;


      const expPercent = nftState ? (nftState.exp / 100) : 1;
      bar.fillStyle(0x666666, 1); // Set the color and alpha  
      bar.fillRoundedRect(healthBarX, healthBarY-20, healthBarWidth, healthBarHeight, 5); // x, y, width, height, radius  
      bar.fillStyle(0xFBF499, 1); // Set the color and alpha  
      bar.fillRoundedRect(healthBarX, healthBarY-20, healthBarWidth * expPercent, healthBarHeight, 5);

      const healthPercent = fightState.health / fightState.maxHealth;
      bar.setAlpha(1);
      bar.fillStyle(0x666666, 1); // Set the color and alpha  
      bar.fillRoundedRect(healthBarX, healthBarY+28-20, healthBarWidth, healthBarHeight, 5); // x, y, width, height, radius  
      bar.fillStyle(0x7FE35E, 1); // Set the color and alpha  
      bar.fillRoundedRect(healthBarX, healthBarY+28-20, healthBarWidth * healthPercent, healthBarHeight, 5);




      const text = globalObjectPoolAbsolute.get(`${tileInfo.unitText}`, "Text") as Phaser.GameObjects.Text;
      text.setAlpha(1);
      // text.setColor("#578C9D")
      text.setShadow(2, 2, '#000000', 2)
        .setStyle({ fontWeight: 'bold' })
      text.setText(`LV     ${nftState ? nftState.heroLevel : "1"}\nHP     ${fightState.health/1000}/${fightState.maxHealth/1000} \nStr    ${Math.floor(fightState.strength / 1000)}\nLuck   ${Math.floor(fightState.luck / 1000)}\nWeapon ${nftState ? `${nftState.weaponLeft}/${nftState.weaponCap}` : "-/-"}`);

      ["strength", "luck", "weapon"].map((stat, index) => {

        // Strength bar
        let percent = 0;
        if (index == 2) {
          percent = nftState ? (nftState.weaponLeft / nftState.weaponCap) : 1
        }else {
          percent = fightState[stat] / fightState[`max${stat[0].toUpperCase() + stat.slice(1)}`];
        }
        bar.fillStyle(0x666666, 1); // Set the color and alpha  
        bar.fillRoundedRect(healthBarX, healthBarY+28*(index+2)-20, healthBarWidth, healthBarHeight, 5); // x, y, width, height, radius  
        bar.fillStyle(0xAA5A58, 1); // Set the color and alpha  
        bar.fillRoundedRect(healthBarX, healthBarY+28*(index+2)-20, healthBarWidth * percent, healthBarHeight, 5);
        bar.setDepth(RenderDepth.AlwaysOnTop);
      })
    }

    /**
     * Unit items
     */

    if (highlightedEntity) {
      const items = getComponentValue(UnitItems, highlightedEntity);
      if (items?.value) {
        for (const [index, item] of items.value.entries()) {
          const sprite = globalObjectPoolAbsolute.get(`${tileInfo.unitItem}-${index}`, "Sprite");
          const itemNum = fromHex(item, "string").replace("Item", "").trim();
          const itemNumber = parseInt(itemNum);
          if (isNaN(itemNumber)) {
            console.warn(`Invalid item number: ${itemNum}`);
            continue;
          }
          const spriteConfig = sprites[Sprites[`Item${itemNumber}`]];
          sprite.setTexture(spriteConfig.assetKey, spriteConfig.frame);
          sprite.x = window.innerWidth - 355 + index * 40
          sprite.y = 170
          sprite.setDepth(RenderDepth.AlwaysOnTop);
          sprite.setScale(1,1);
          sprite.setAlpha(1);
        }
      }
    }
  })

  defineSystem(world, [Has(HoverHighlight)], ({ entity, type }) => {
    const highlightEntity = `${entity}-hover-highlight` as Entity;
    if (type === UpdateType.Exit) {
      globalObjectPool.remove(highlightEntity);
      return;
    }
    const hoverHighlght = getComponentValueStrict(HoverHighlight, singletonEntity);
    const sprite = globalObjectPool.get(highlightEntity, "Sprite");
    const position = { x: hoverHighlght.x, y: hoverHighlght.y };
    const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
    sprite.setPosition(pixelCoord.x, pixelCoord.y);
    playTintedAnimation(highlightEntity, Animations.TileSelect, "white");
    sprite.setDepth(RenderDepth.Foreground1);
  });

  setInterval(() => {
    const balance = getLKBalance()
    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-lk-balance`, "Text").setText(`${balance.value}`).setShadow(2, 2, '#000000', 2)
    globalObjectPoolAbsolute.get(`${singletonEntity}-ui-lk-balance-1`, "Text").setText(`${balance.value}`).setShadow(2, 2, '#000000', 2)
  }, 2000);
}
