import { ComponentUpdate, defineSystem, Entity, getComponentValue, Has, Not, UpdateType, runQuery, HasValue, hasComponent } from "@latticexyz/recs";
import { PhaserLayer, RenderDepth } from "../../types";
import { Sprites } from "../../phaserConstants";
import { UNIT_OFFSET } from "../../../../Local/constants";


/**
 * Input: 
 *      - PotentialPath: set by [Local]PotentialPathSystem <-- [Local]SelectionSystem
 *      - LocalPosition: set by [Local]SyncSystem
 *      - !NextPosition: set by [Phaser]regiserClicks
 * Output:
 *      - Highlight MovementArea of selected unit
 * Explanation: 
 *      - Active only before destination commited. Reflect by !NextPosition
 *      - Apply Non-Owned unit
 * @param layer 
 */
export function drawMovementArea(layer: PhaserLayer){
	const {
    world,
    api: {
      drawSpriteAtTile,
      drawTileHighlight,
    },
    parentLayers: {
      network: {
        components: { Position, TerrainType, UnitType, Healing },
        utils: { isOwnedByCurrentPlayer },
      },
      local: {
        components: { PotentialPath, LocalPosition },
      },
      headless: {
        components: { NextPosition, InCurrentMatch },
      },
    },
    globalObjectPool,
  } = layer;


  defineSystem(world, [Has(PotentialPath), Has(LocalPosition), Not(NextPosition)], (update) => {
    draw(update);
  });

  /**
   * @param update: for extracting attached entity and update type 
   */
  function draw(update: ComponentUpdate & { type: UpdateType }) {
    const { entity } = update

    const healing = getComponentValue(Healing, entity);
    if (healing) {
      console.log("healing", healing);
    }
    /**
     * TODO: clear drawed stuff
     * idgaf just wipe every path
     */
    removePathObjects(entity, {
      x: Array.from({ length: 250 }, () => 0),
      y: Array.from({ length: 250 }, () => 0),
    });
    if (update.type == UpdateType.Exit) {
      return
    }

    const potentialPaths = getComponentValue(PotentialPath, entity);
    if (!potentialPaths) { return; }
      /**
       * Different affect while selecting opponent's unit
       */
      const currentPlayerEntity = isOwnedByCurrentPlayer(entity);
      const pathColor = "yellow";
      const alpha = currentPlayerEntity ? 1 : 0.5;

      /**
       * Current position not included by default. Add it
       */
      const position = getComponentValue(LocalPosition, entity);
      if (!position) return;
      potentialPaths.x.push(position.x);
      potentialPaths.y.push(position.y);
      // Convert potentialPaths array to Map for efficient lookup
      const movePathMap = new Map<string, boolean>();
      for (let i = 0; i < potentialPaths.x.length; i++) {
        const key = `${potentialPaths.x[i]}_${potentialPaths.y[i]}`;
        movePathMap.set(key, true);
      }

      /**
       * - Find and draw attack boundary
       * - Draw movement boundary
       */
      const attackRange = new Map<String, boolean>()
      for (let i = 0; i < potentialPaths.x.length; i++) {
        const position = { x: potentialPaths.x[i], y: potentialPaths.y[i] };
        const keys = [
          (position.x + 1) + "_" + position.y,
          (position.x - 1) + "_" + position.y,
          (position.x) + "_" + (position.y+1),
          (position.x) + "_" + (position.y-1),
        ]
        loop: for (let k=0; k < keys.length; k++) {
          const xx = +keys[k].split("_")[0]
          const yy = +keys[k].split("_")[1]
          if (attackRange.get(keys[k])) {
            continue loop
          }
          attackRange.set(keys[k], true)


          /**
           * ID auto increment for attackable tiles
           */

          if (!movePathMap.get(keys[k])) {
            drawTileHighlight(`${entity}-path-highlight-${potentialPaths.x.length + attackRange.size + 1}`, { x: xx, y: yy }, 1, alpha);
          }
        }
        /**
         * ID of movalbe tiles
         */
        drawTileHighlight(`${entity}-path-highlight-${i}`, position, 0, alpha);
      }
      
      // check attackRange to filter opponent unit and draw attack mark
      
      if (!isOwnedByCurrentPlayer(entity)) {
        return
      }
      let index = 0 
      for (let [key, value] of attackRange) {
        index++
        const x:number = +key.split("_")[0]
        const y:number = +key.split("_")[1]
        const targetPosition = { x: x, y: y }
        const e = [...runQuery([Has(InCurrentMatch), HasValue(Position, targetPosition), Not(TerrainType)])][0];
        if (e) {
          const id = `${index}-attackable-highlight`;
          const outlineId = `${index}-attackable-outline`;
          let swordSprite = Sprites.Sword;
          // drawSpriteAtTile(id, swordSprite, targetPosition, RenderDepth.UI2, {
          //   yOffset: hasComponent(UnitType, entity) ? -1 * UNIT_OFFSET : 0,
          // });
          if (isOwnedByCurrentPlayer(e)) {
            if (healing && e != entity) {
              drawTileHighlight(outlineId, targetPosition, 3);
            }
          }else {
            drawTileHighlight(outlineId, targetPosition, 2);
          }
        }
      }
  }
  function removePathObjects(entity: Entity, paths: { x: number[]; y: number[] }) {
    for (let i = 0; i < paths.x.length; i++) {
      globalObjectPool.remove(`${entity}-path-highlight-${i}`);
    }
    for (let i = 0; i < 50; i++) {
      globalObjectPool.remove(`${i}-attackable-highlight`);
      globalObjectPool.remove(`${i}-attackable-outline`);
    }
  }
}
