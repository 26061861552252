

import {
  Entity,
  Has,
  HasValue,
  getComponentValue,
  getComponentValueStrict,
  hasComponent,
  runQuery,
  setComponent,
} from "@latticexyz/recs";
import { NetworkConfig } from "../../mud/utils";
import { decodeEntity, encodeEntity, singletonEntity } from "@latticexyz/store-sync/recs";
import { decodeValue, KeySchema } from "@latticexyz/protocol-parser/internal";
import { hexToResource } from "@latticexyz/common";
import { useStore } from "../../useStore";
import { addressToEntityID } from "../../mud/setupNetwork";
import { setup } from "../../mud/setup";
import { WorldCoord } from "phaserx/src/types";
import { createSystemExecutor } from "./createSystemExecutor";
import { matchIdFromEntity } from "../../matchIdFromEntity";
import { SPAWN_SETTLEMENT } from "../../constants";
import { Hex } from "viem";
import { getBalance } from "viem/actions";
import IWorldAbi from "contracts/out/IWorld.sol/IWorld.abi.json";
import {
  ACTION_SYSTEM_ID, TOKEN_SYSTEM_ID
} from "../../constants";

import { decodeMatchEntity } from "../../decodeMatchEntity";
import { encodeMatchEntity } from "../../encodeMatchEntity";
import { encodeSystemCallFrom } from "@latticexyz/world/internal";

import { createWalletBalanceSystem } from "./systems/WalletBalanceSystem";

import { fireEmblemDebug } from "../../debug";
/**
 * The Network layer is the lowest layer in the client architecture.
 * Its purpose is to synchronize the client components with the contract components.
 */

const debug = fireEmblemDebug.extend("network-layer");

export async function createNetworkLayer(config: NetworkConfig) {
  const { network, components } = await setup(config);
  const { worldContract, matchEntity: currentMatchEntity } = network;
  const currentMatchId = currentMatchEntity ? matchIdFromEntity(currentMatchEntity) : null;

  const isBrowser = typeof window !== "undefined";

  const getAnalyticsConsent = () => false;

  const calculateMeanTxResponseTime = () => {
    const allTransactions = [...runQuery([Has(components.Transaction)])];

    return (
      allTransactions.reduce((acc, entity) => {
        const transaction = getComponentValue(components.Transaction, entity);
        if (!transaction || transaction.status !== "completed") return acc;

        const responseTime = Number((transaction.completedTimestamp ?? 0n) - (transaction.submittedTimestamp ?? 0n));
        return acc + responseTime;
      }, 0) /
      (allTransactions.filter((t) => getComponentValue(components.Transaction, t)?.status === "completed").length || 1)
    );
  };

  const { executeSystem, executeSystemWithExternalWallet } = createSystemExecutor({
    worldContract,
    network,
    components,
    sendAnalytics: getAnalyticsConsent(),
    calculateMeanTxResponseTime,
  });

  const getLevelIndices = (mapId: string, bPrintId: string) => {
    const { MapBPrints } = components;

    const bPrintIds = getComponentValue(MapBPrints, mapId as Entity);

    if (!bPrintIds) {
      return [];
    }

    const initialValue: bigint[] = [];
    return bPrintIds.value.reduce(
      (c, _bPrintId, i) => {
        // (_bPrintId === bPrintId ? c.concat(BigInt(i)) : c)
        if (_bPrintId === bPrintId) {
          const pos = getMapPositionStrict(mapId as Hex, BigInt(i))
          return c.concat(BigInt(i))
        }
        return c
      },
      initialValue,
    );
  };

  const getLevelSpawns = (mapId: string) => {
    return getLevelIndices(mapId, SPAWN_SETTLEMENT);
  };

  const getAvailableLevelSpawns = (mapId: string, matchEntity: Hex) => {
    const { PlayerAtIndex } = components;

    return getLevelSpawns(mapId).filter((index) => {
      const reserved = hasComponent(
        PlayerAtIndex,
        encodeEntity(PlayerAtIndex.metadata.keySchema, { matchEntity, index }),
      );

      return !reserved;
    });
  };

  function decodeData(tableId: Hex, staticData: Hex) {
    const { name } = hexToResource(tableId as Hex);
    const component = components[name as keyof typeof components];

    // Workaround, custom decoding for user-defined types
    return ["TerrainType", "StructureType", "UnitType"].includes(name)
      ? decodeValue({ value: "uint8" }, staticData as Hex)
      : decodeValue((component.metadata as any)?.valueSchema, staticData as Hex);
  }

  function getTemplateValueStrict(tableId: Hex, bPrintId: Hex) {
    // Workaround, custom key schema for user-defined types
    const keySchema: KeySchema = {
      ...components.BPrintCompInits.metadata.keySchema,
      tableId: "bytes32",
    };

    const { staticData } = getComponentValueStrict(
      components.BPrintCompInits,
      encodeEntity(keySchema, { bPrintId, tableId }),
    );

    return decodeData(tableId, staticData as Hex);
  }

  function getMapPositionStrict(mapId: Hex, index: bigint) {
    return getComponentValueStrict(
      components.MapPosition,
      encodeEntity(components.MapPosition.metadata.keySchema, {
        mapId,
        index,
      }),
    );
  }

  function getLevelDatum(mapId: Hex, index: bigint) {
    const bPrintId = getComponentValueStrict(components.MapBPrints, mapId as Entity).value[Number(index)];

    const componentValues: Record<string, any> = {};

    const { value: templateTableIds } = getComponentValueStrict(components.BPrintComps, bPrintId as Entity);
    templateTableIds.forEach((tableId) => {
      const { name } = hexToResource(tableId as Hex);

      componentValues[name] = getTemplateValueStrict(tableId as Hex, bPrintId as Hex);
    });

    componentValues["Position"] = getMapPositionStrict(mapId, index);

    return componentValues;
  }
  // Get the data for all level indices that have a virtual template
  function getVirtualLevelData(mapId: Entity) {
    const { value: bPrintIds } = getComponentValueStrict(components.MapBPrints, mapId);
    const initialValue: Record<string, any>[] = [];
    return bPrintIds.reduce((result, bPrintId, i) => {
      if (hasComponent(components.StaticMapBPrints, bPrintId as Entity)) {
        result.push(getLevelDatum(mapId as Hex, BigInt(i)));
      }
      return result;
    }, initialValue);
  }

  function getItemsData(){
    const { value: items } = getComponentValueStrict(components.Items, singletonEntity);
    const res = items.reduce((acc, bPrintId) => {
      const componentValues: Record<string, any> = {};
      const { value: templateTableIds } = getComponentValueStrict(components.BPrintComps, bPrintId as Entity);
      templateTableIds.forEach((tableId) => {
        const { name } = hexToResource(tableId as Hex);
  
        componentValues[name] = getTemplateValueStrict(tableId as Hex, bPrintId as Hex);
      });
      acc[bPrintId] = componentValues;
      return acc;
    }, {} as Record<string, Record<string, any>>)
    
    return res
  }
  
  function getPlayerEntity(
    address: string | undefined,
    matchEntity: Entity | null = currentMatchEntity,
  ): Entity | undefined {
    if (!address) return;
    if (!matchEntity) return;
    
    const addressEntity = address as Entity;
    const playerEntity = [
      ...runQuery([
        HasValue(components.CreatedByAddress, { value: addressEntity }),
        Has(components.Player),
        HasValue(components.Match, { matchEntity }),
      ]),
    ][0];

    return playerEntity;
  }

  function getOwningPlayer(entity: Entity): Entity | undefined {
    if (hasComponent(components.Player, entity)) {
      return entity;
    }
    if (hasComponent(components.OwnedBy, entity)) {
      return getComponentValueStrict(components.OwnedBy, entity).value as Entity;
    }
    return;
  }
  function isOwnedBy(entity: Entity, player: Entity) {
    const owningPlayer = getOwningPlayer(entity);
    return owningPlayer && owningPlayer === player;
  }


  let currentPlayerEntity: Entity | undefined;
  function getCurrentPlayerEntity(): Entity | undefined {
    if (currentPlayerEntity) return currentPlayerEntity;

    const { externalWalletClient } = useStore.getState();

    if (externalWalletClient && externalWalletClient.account) {
      currentPlayerEntity = getPlayerEntity(addressToEntityID(externalWalletClient.account.address));
      return currentPlayerEntity;
    }

    return;
  }

  /**
   * THE PERFORMANCE ON THIS IS FUCKED
   * NEED TO FIND OUT WHY
   */
  function isOwnedByCurrentPlayer(entity: Entity): boolean {
    // Units do not change ownership, so we can calculate
    // and cache the result inside of the UnitOwnedByCurrentPlayerSystem
    const isUnit = hasComponent(components.UnitType, entity);
    const ownedByCurrentPlayerAlreadySet = hasComponent(components.OwnedByCurrentPlayer, entity);
    if (isUnit && ownedByCurrentPlayerAlreadySet) {
      return getComponentValueStrict(components.OwnedByCurrentPlayer, entity).value;
    }

    const x = getCurrentPlayerEntity();
    if (!x) {
      return false;
    }

    const player = decodeEntity(components.Player.metadata.keySchema, x).entity;
    if (player) {
      return Boolean(isOwnedBy(entity, player as Entity));
    }

    return false;
  }

  async function move(entity: Entity, path: WorldCoord[]) {

    if (!currentMatchEntity) return;

    const finalPoint = path[path.length - 1];
    const { externalWalletClient } = useStore.getState();
    if (!externalWalletClient?.account) return;
    await executeSystem({
      entity,
      systemCall: "callFrom",
      systemId: "Move",
      confirmCompletionCallback: () => {
        return new Promise((resolve) => {
          const sub = components.Position.update$.subscribe((update) => {
            if (update.entity !== entity) return;

            const [val] = update.value;
            const position = val;

            if (position?.x !== finalPoint.x || position?.y !== finalPoint.y) return;

            resolve();
            sub.unsubscribe();
          });
        });
      },
      args: [
        encodeSystemCallFrom({
          abi: IWorldAbi,
          from: externalWalletClient.account.address,
          systemId: ACTION_SYSTEM_ID,
          functionName: "moveOnly",
          args: [currentMatchEntity as Hex, decodeMatchEntity(entity).entity, path],
        }),
      ]
    })
  }

  async function moveThenFight(attacker: Entity, path: WorldCoord[], defender: Entity) {
    if (!currentMatchEntity) return;

    const { externalWalletClient } = useStore.getState();
    if (!externalWalletClient?.account) return;

    await executeSystem({
      entity: attacker,
      systemCall: "callFrom",
      systemId: "MoveAndAttack",
      confirmCompletionCallback: () => {
        return new Promise((resolve) => {
          const sub = components.FightOutcome.update$.subscribe((update) => {
            if (update.entity !== attacker) return;

            const [val] = update.value;
            const fightStateResult = val;

            if (encodeMatchEntity(currentMatchEntity, fightStateResult?.attacker ?? "0x0") !== attacker) return;

            resolve();
            sub.unsubscribe();
          });
        });
      },
      args: [
        encodeSystemCallFrom({
          abi: IWorldAbi,
          from: externalWalletClient.account.address,
          systemId: ACTION_SYSTEM_ID,
          functionName: "moveThenFight",
          args: [
            currentMatchEntity as Hex,
            decodeMatchEntity(attacker).entity,
            path,
            decodeMatchEntity(defender).entity,
          ],
        }),
      ],
    });
  }

  const hasPendingAction = (entity: Entity) => {
    const pendingAction = [
      ...runQuery([
        HasValue(components.Action, {
          entity,
          status: "pending",
        }),
      ]),
    ][0];

    return Boolean(pendingAction);
  };

  async function attack(attacker: Entity, defender: Entity) {
    if (!currentMatchEntity) return;


    const { externalWalletClient } = useStore.getState();
    if (!externalWalletClient?.account) return;

    await executeSystem({
      entity: attacker,
      systemCall: "callFrom",
      systemId: "Attack",
      confirmCompletionCallback: () => {
        return new Promise((resolve) => {
          const sub = components.FightOutcome.update$.subscribe((update) => {
            if (update.entity !== attacker) return;

            const [val] = update.value;
            const fightStateResult = val;

            if (encodeMatchEntity(currentMatchEntity, fightStateResult?.attacker ?? "0x0") !== attacker) return;

            resolve();
            sub.unsubscribe();
          });
        });
      },
      args: [
        encodeSystemCallFrom({
          abi: IWorldAbi,
          from: externalWalletClient.account.address,
          systemId: ACTION_SYSTEM_ID,
          functionName: "fightOnly",
          args: [currentMatchEntity as Hex, decodeMatchEntity(attacker).entity, decodeMatchEntity(defender).entity],
        }),
      ],
    });
  }
  
  async function botReact(attackers: Entity[], paths: WorldCoord[][], defenders: Entity[]) {
    if (!currentMatchEntity) return;

    const { externalWalletClient } = useStore.getState();
    if (!externalWalletClient?.account) return;

    await executeSystem({
      entity: singletonEntity, // Use singleton to prevent spaming end turn
      systemCall: "callFrom",
      systemId: "BotReact",
      confirmCompletionCallback: () => {
        return new Promise((resolve) => {
          let completedActions = 0;
          const totalActions = attackers.length;
          const sub = components.FightOutcome.update$.subscribe((update) => {
            const [val] = update.value;
            const fightStateResult = val;

            if (attackers.includes(encodeMatchEntity(currentMatchEntity, fightStateResult?.attacker ?? "0x0"))) {
              completedActions++;
            }

            if (completedActions === totalActions) {
              resolve();
              sub.unsubscribe();
            }
          });
        });
      },
      args: [
        encodeSystemCallFrom({
          abi: IWorldAbi,
          from: externalWalletClient.account.address,
          systemId: ACTION_SYSTEM_ID,
          functionName: "botReact",
          args: [
            currentMatchEntity as Hex,
            attackers.map(attacker => decodeMatchEntity(attacker).entity),
            paths,
            defenders.map(defender => decodeMatchEntity(defender).entity),
          ],
        }),
      ],
    });
  }


  const refreshBalance = async (address: Hex) => {
    try {
      debug(`Refreshing wallet balance for address: ${address}`);
      const balance = await getBalance(network.walletClient, {
        address,
      });
      const addressEntity = addressToEntityID(address);
      setComponent(components.WalletBalance, addressEntity, {
        value: balance,
      });
    } catch (e) {
      debug(`Failed to fetch external wallet balance for address ${address}`);
    }
  };


  async function weaponTopup(entity: Entity) {
    if (!currentMatchEntity) return;
    const { externalWalletClient } = useStore.getState();
    if (!externalWalletClient?.account) return;
    await executeSystem({
      entity,
      systemCall: "callFrom",
      systemId: "WeaponTopup",
      confirmCompletionCallback: () => {
        return new Promise((resolve) => {
          resolve();
        });
      },
      args: [
        encodeSystemCallFrom({
          abi: IWorldAbi,
          from: externalWalletClient.account.address,
          systemId: ACTION_SYSTEM_ID,
          functionName: "weaponTopup",
          args: [currentMatchEntity as Hex, decodeMatchEntity(entity).entity],
        }),
      ]
    })
  }

  function getLKBalance() {
    const { externalWalletClient } = useStore.getState();
    if (!externalWalletClient?.account) return;
    return getComponentValueStrict(components.LK_Balances, addressToEntityID(externalWalletClient.account.address))
  }

  const layer = {
      world: network.world,
      network,
      components: {
        ...network.components,
        ...components,
      },
      executeSystem,
      executeSystemWithExternalWallet,
      api: {
        move,
        moveThenFight,
        attack,
        botReact,
        weaponTopup,
        getLKBalance,
      },
      utils: {
        getVirtualLevelData,
        getItemsData,
        getAvailableLevelSpawns,
        getLevelSpawns,
        getLevelIndices,
        getPlayerEntity,
        getOwningPlayer,
        isOwnedBy,
        isOwnedByCurrentPlayer,
        hasPendingAction,

        refreshBalance,
      },
  }    
  createWalletBalanceSystem(layer);
  return layer
}
